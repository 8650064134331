export enum TypeMachineExport {
  Signals = "signals",
  Sensors = "sensors",
  Events = "events",
}

export enum Status {
  void,
  idle,
  loading,
  error,
}

export enum DataTypeSignals {
  Unknown = "Unknown",
  Trend = "Trend",
  FastTrend = "FastTrend",
  ShortTerm = "ShortTerm",
  Raw = "Raw",
}

export enum BooleanDefault {
  True = "True",
  False = "False",
}

export enum FilesExtensions {
  Csv = "csv",
  Xlsx = "xlsx",
}

export enum ImageUploadExtensions {
  Sav = "sav",
  Zip = "zip",
  GZip = "gzip",
}

export enum AlertLevelDirection {
  Unknown = "Unknown",
  Low = "Low",
  High = "High",
  LowAndHigh = "LowAndHigh",
}

export enum AlertLevelNotification {
  Unknown = "Unknown",
  Internal = "Internal",
  External = "External",
  InternalAndExternal = "InternalAndExternal",
}

export enum SignalCondition {
  NoStatus = "NoStatus",
  NotInstalled = "NotInstalled",
  Faulty = "Faulty",
  Ok = "Ok",
  Indication = "Indication",
  Alert = "Alert",
  Danger = "Danger",
}

export enum PersonaType {
  dalogAdministrator = "Dalog Administrator",
  dalogContributor = "Dalog Contributor",
  dalogUser = "Dalog User",
  customerUser = "Customer User",
  customerMobileUser = "Customer Mobile User",
  imageUploaderCustomer = "Image Uploader Customer",
}

export enum ScopeLevelType {
  Root = "Root",
  Corporation = "Corporation",
  Company = "Company",
  Project = "Project",
  Machine = "Machine",
}

export enum MemberType {
  User = "User",
  ServiceAccount = "ServiceAccount",
}

export enum RoleType {
  UserAdministrator = "UserAdministrator",
  MetaDataContributor = "MetaDataContributor",
  MetaDataReader = "MetaDataReader",
  MeasuredDataContributor = "MeasuredDataContributor",
  MeasuredDataReader = "MeasuredDataReader",
  MachineCvContributor = "MachineCvContributor",
  MachineCvReader = "MachineCvReader",
  MachineCvAdministrator = "MachineCvAdministrator",
  AuditReader = "AuditReader",
  PowerBiReader = "PowerBiReader",
  PowerBiContributor = "PowerBiContributor",
  ImageContributor = "ImageContributor",
  WirelessSensorReader = "WirelessSensorReader",
  WirelessSensorContributor = "WirelessSensorContributor",
  FlsImageContributor = "FlsImageContributor",
  ImageAdministrator = "ImageAdministrator",
  PermaContributor = "PermaContributor",
  PermaDataContributor = "PermaDataContributor",
  PermaReader = "PermaReader",
  SubmissionAdministrator = "SubmissionAdministrator",
  SubmissionContributor = "SubmissionContributor",
  VpnContributor = "VpnContributor",
  VpnReader = "VpnReader",
  WirelessImageContributor = "WirelessImageContributor",
  MeasuredDataTier1Reader = "MeasuredDataTier1Reader",
  MeasuredDataTier2Reader = "MeasuredDataTier2Reader",
  MeasuredDataTier3Reader = "MeasuredDataTier3Reader",
  MeasuredDataTier4Reader = "MeasuredDataTier4Reader",
  MeasuredDataTier5Reader = "MeasuredDataTier5Reader",
  MobileAppUser = "MobileAppUser",
}

export enum GroupType {
  Custom = "Custom",
  External = "External",
  System = "System",
}

export enum UserState {
  Active = "Active",
  Blocked = "Blocked",
  Deleted = "Deleted",
  Pending = "Pending",
}

export enum SubscriptionState {
  Active = "Active",
  Cancelled = "Cancelled",
  Expired = "Expired",
  Rejected = "Rejected",
  Submitted = "Submitted",
  Suspended = "Suspended",
}

export enum ProductState {
  NotPublished = "NotPublished",
  Published = "Published",
}

export enum ConditionStatusNot {
  Ok = 0,
  Indication = 1,
  Alert = 2,
  Danger = 3,
  Faulty = -1,
  NoStatus = -2,
  NotInstalled = -3,
}

export enum ConditionStatus {
  Ok = "Ok",
  Indication = "Indication",
  Alert = "Alert",
  Danger = "Danger",
  Faulty = "Faulty",
  NoStatus = "NoStatus",
  NotInstalled = "NotInstalled",
}
