import {
  Spinner,
  Stack,
  Text,
  Sticky,
  StickyPositionType,
  TooltipHost,
  SpinnerSize,
  IFacepilePersona,
  Facepile,
} from "@fluentui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useAuthorization } from "../../Hooks/authorization";
import { Status } from "../../schema/status";

import {
  selectPermissionsStatus,
  listAsyncPermi,
  selectPermissionsTable,
} from "./reducer";

import {
  EntityItemWithAccountRolesToMapInTable,
  ResponseUserOrServiceAccount,
} from "./models";
import {
  BaseCommandBar,
  Column,
  CommandBarItemProps,
  CommandBarItemType,
  NoData,
  Table,
  computeCommandBarItems,
  notification,
  useTableFilters,
} from "web-analysis-lib";
import { DeleteConfirm } from "./DeleteConfirm";
import { camelCaseToSpacedString, extract4Initials } from "../../schema/Utils";
import {
  commandBarStyles,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import { CustomItemsType } from "../Roles/ScopesOfRole/reducer";
import { AddDialog } from "./AddDialogNew";
import { Personas } from "../../schema/personas";
import { mapInitialsToNumber } from "../../schema/Utils";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";

export type ControlledItem = CustomItemsType & {
  check: boolean;
};

type GetColumnsOpts = {
  isLoading?: string;
};

const headerTooltip = (text: string) => {
  return (
    <div style={{ justifyContent: "center" }}>
      <TooltipHost
        content={camelCaseToSpacedString(text)}
        style={{ justifyContent: "center" }}
      >
        <span style={{ justifyContent: "center" }}>
          {extract4Initials(camelCaseToSpacedString(text))}
        </span>
      </TooltipHost>
    </div>
  );
};

const checkItem = () => {
  return <Stack horizontalAlign="center">X</Stack>;
};

const getColumns = ({ isLoading }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "issuer",
      name: "Issuer",
      fieldName: "issuer",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "displayName",
      name: "Display Name",
      fieldName: "displayName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "email",
      name: "Email",
      fieldName: "email",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "type",
      name: "Type",
      fieldName: "type",
      minWidth: 50,
      isSortable: true,
    },
    {
      key: "corporationName",
      name: "Corporation Name",
      fieldName: "corporationName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "companyName",
      name: "Company Name",
      fieldName: "companyName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "projectName",
      name: "Project Name",
      fieldName: "projectName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "machineName",
      name: "Machine Name",
      fieldName: "machineName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "persona",
      name: "Persona",
      fieldName: "persona",
      minWidth: 150,
      isSortable: true,
      onRender: (item: EntityItemWithAccountRolesToMapInTable) => {
        let persona = Personas.GetUserPersona(item);
        let personas: IFacepilePersona[] = [];
        if (persona) {
          personas.push({
            personaName: persona.name,
            imageInitials: persona.imageInitials,
            initialsColor: mapInitialsToNumber(persona.imageInitials),
          });
        } else {
          personas.push(Personas.undetermined);
        }

        return <Facepile personas={personas} overflowPersonas={personas} />;
      },
    },
    {
      key: "userAdministrator",
      name: extract4Initials(camelCaseToSpacedString("userAdministrator")),
      fieldName: "userAdministrator",
      minWidth: 50,
      isSortable: true,
      onRender: ({ userAdministrator }) =>
        userAdministrator ? checkItem() : "",
      onRenderHeader: () => headerTooltip("userAdministrator"),
    },
    {
      key: "metaDataReader",
      name: extract4Initials(camelCaseToSpacedString("metaDataReader")),
      fieldName: "metaDataReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ metaDataReader }) => (metaDataReader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("metaDataReader"),
    },

    {
      key: "metaDataContributor",
      name: "Meta Data Contributor",
      fieldName: "metaDataContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ metaDataContributor }) =>
        metaDataContributor ? checkItem() : "",
      onRenderHeader: () => headerTooltip("metaDataContributor"),
    },
    {
      key: "auditReader",
      name: "Audit Reader",
      fieldName: "auditReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ auditReader }) => (auditReader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("auditReader"),
    },
    {
      key: "measuredDataReader",
      name: "Measured Data Reader",
      fieldName: "measuredDataReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ measuredDataReader }) =>
        measuredDataReader ? checkItem() : "",
      onRenderHeader: () => headerTooltip("measuredDataReader"),
    },
    {
      key: "measuredDataContributor",
      name: "Measured Data Contributor",
      fieldName: "measuredDataContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ measuredDataContributor }) =>
        measuredDataContributor ? checkItem() : "",
      onRenderHeader: () => headerTooltip("measuredDataContributor"),
    },
    {
      key: "machineCvReader",
      name: "Machine Cv Reader",
      fieldName: "machineCvReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ machineCvReader }) => (machineCvReader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("machineCvReader"),
    },
    {
      key: "machineCvContributor",
      name: "Machine Cv Contributor",
      fieldName: "machineCvContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ machineCvContributor }) =>
        machineCvContributor ? checkItem() : "",
      onRenderHeader: () => headerTooltip("machineCvContributor"),
    },
    {
      key: "powerBiReader",
      name: "Power Bi Reader",
      fieldName: "powerBiReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ powerBiReader }) => (powerBiReader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("powerBiReader"),
    },
    {
      key: "powerBiContributor",
      name: "Power Bi Contributor",
      fieldName: "powerBiContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ powerBiContributor }) =>
        powerBiContributor ? checkItem() : "",
      onRenderHeader: () => headerTooltip("powerBiContributor"),
    },
    {
      key: "imageContributor",
      name: "Image Contributor",
      fieldName: "imageContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ imageContributor }) => (imageContributor ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("imageContributor"),
    },
    {
      key: "wirelessSensorReader",
      name: "Wireless Sensor Reader",
      fieldName: "wirelessSensorReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ wirelessSensorReader }) =>
        wirelessSensorReader ? checkItem() : "",
      onRenderHeader: () => headerTooltip("wirelessSensorReader"),
    },
    {
      key: "wirelessSensorContributor",
      name: "Wireless Sensor Contributor",
      fieldName: "wirelessSensorContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ wirelessSensorContributor }) =>
        wirelessSensorContributor ? checkItem() : "",
      onRenderHeader: () => headerTooltip("wirelessSensorContributor"),
    },
    {
      key: "mobileAppUser",
      name: "Mobile App User",
      fieldName: "mobileAppUser",
      minWidth: 50,
      isSortable: true,
      onRender: ({ mobileAppUser }) => (mobileAppUser ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("mobileAppUser"),
    },
  ];
  return columns;
};

export const ResponseRolesList: React.FunctionComponent = () => {
  const auth = useContext(authContext);
  const permissions = useAppSelector(selectPermissionsTable);
  const status = useAppSelector(selectPermissionsStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listAsyncPermi());
  }, [dispatch]);

  const [selected, setSelected] = useState<{
    data: ResponseUserOrServiceAccount | null;
    context: "add" | "edit" | "delete";
  } | null>(null);

  const { filters, handleSearch } =
    useTableFilters<EntityItemWithAccountRolesToMapInTable>({
      keys: [
        "issuer",
        "displayName",
        "email",
        "type",
        "corporationName",
        "companyName",
        "projectName",
        "machineName",
      ],
    });

  const addOnclick = (ev): boolean | void => {
    setSelected({ data: undefined, context: "add" });
  };

  const commandBarItems: CommandBarItemProps[] = useMemo(() => {
    return [
      {
        key: "title",
        type: CommandBarItemType.Custom,
        onRender: () => <Text style={titleStyle}>Permissions</Text>,
      },
      ...(auth.userAdministrator
        ? [
            {
              key: "add",
              text: "Add",
              type: CommandBarItemType.Button,
              iconProps: { iconName: "Add" },
              onClick: addOnclick,
            },
          ]
        : []),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.loading]);

  return (
    <>
      <DeleteConfirm
        data={selected?.data}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(
              `Failed deleting ${selected?.data?.displayName}: ${selected?.data?.memberType}`
            );
          } else {
            dispatch(listAsyncPermi());
            notification.success(
              `${selected?.data?.displayName}: ${selected?.data?.memberType} deleted successfully`
            );
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />

      {selected?.context === "add" && (
        <AddDialog
          show={selected?.context === "add"}
          onClose={() => {
            setSelected(null);
          }}
          onSuccess={() => {
            dispatch(listAsyncPermi());
            setSelected(null);
          }}
        />
      )}

      {status === Status.idle ? (
        <div style={pageStyle}>
          <Sticky stickyPosition={StickyPositionType.Both}>
            <BaseCommandBar
              items={computeCommandBarItems(commandBarItems)}
              styles={commandBarStyles}
              onSearch={handleSearch}
            />
          </Sticky>
          <Table
            items={permissions}
            columns={getColumns({ isLoading: "" })}
            persistOpts={{
              key: "table-permissions",
              version: 2,
            }}
            hasSelection={false}
            filters={filters}
          ></Table>
        </div>
      ) : status === Status.loading || auth.loading ? (
        <div style={{ height: "80vh" }}>
          <Stack verticalFill verticalAlign="center">
            <Spinner size={SpinnerSize.large} label="Loading..."></Spinner>
          </Stack>
        </div>
      ) : (
        <>
          <Stack
            styles={{ root: { height: "100vh" } }}
            verticalAlign="space-around"
          >
            <NoData />
          </Stack>
        </>
      )}
    </>
  );
};
