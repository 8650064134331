import { IFacepilePersona } from "@fluentui/react";
import {
  EntityItemWithAccountRolesToMapInTable,
  roleMappings,
} from "../Components/Permissions/models";
import { MemberType, PersonaType, RoleType } from "./status";
import { mapInitialsToNumber } from "./Utils";

export type personaProps = {
  name: PersonaType;
  personaRoles: RoleType[];
  root: boolean;
  type: MemberType;
  imageInitials: string;
};

export class Personas {
  public static undetermined: IFacepilePersona = {
    personaName: "Undetermined",
    imageInitials: "UNB",
    initialsColor: mapInitialsToNumber("UNB"),
  };

  public static dalogAdministrator: RoleType[] = [
    RoleType.AuditReader,
    RoleType.ImageContributor,
    RoleType.MachineCvAdministrator,
    RoleType.MeasuredDataContributor,
    RoleType.MeasuredDataTier5Reader,
    RoleType.MetaDataContributor,
    RoleType.PowerBiContributor,
    RoleType.SubmissionAdministrator,
    RoleType.VpnContributor,
    RoleType.UserAdministrator,
    RoleType.WirelessSensorContributor,
    RoleType.MobileAppUser,
  ];

  public static dalogContributor: RoleType[] = [
    RoleType.AuditReader,
    RoleType.ImageContributor,
    RoleType.MachineCvContributor,
    RoleType.MeasuredDataReader,
    RoleType.MetaDataContributor,
    RoleType.PowerBiContributor,
    RoleType.SubmissionAdministrator,
    RoleType.WirelessSensorContributor,
    RoleType.MobileAppUser,
  ];

  public static dalogUser: RoleType[] = [
    RoleType.ImageContributor,
    RoleType.MachineCvContributor,
    RoleType.MeasuredDataReader,
    RoleType.MetaDataReader,
    RoleType.PowerBiReader,
    RoleType.SubmissionContributor,
    RoleType.WirelessSensorReader,
    RoleType.MobileAppUser,
  ];

  public static customerUser: RoleType[] = [
    RoleType.MachineCvContributor,
    RoleType.MeasuredDataReader,
    RoleType.MeasuredDataTier2Reader,
    RoleType.MetaDataReader,
    RoleType.PowerBiReader,
    RoleType.SubmissionContributor,
  ];

  public static customerMobileUser: RoleType[] = [
    RoleType.MachineCvContributor,
    RoleType.MeasuredDataReader,
    RoleType.MeasuredDataTier2Reader,
    RoleType.MetaDataReader,
    RoleType.PowerBiReader,
    RoleType.SubmissionContributor,
    RoleType.MobileAppUser,
  ];

  public static imageUploaderCustomer: RoleType[] = [
    RoleType.ImageContributor,
    RoleType.MeasuredDataReader,
    RoleType.MetaDataReader,
  ];
  public static allPersonas: personaProps[] = [
    {
      name: PersonaType.dalogAdministrator,
      personaRoles: this.dalogAdministrator,
      root: true,
      type: MemberType.User,
      imageInitials: "DA",
    },
    {
      name: PersonaType.dalogContributor,
      personaRoles: this.dalogContributor,
      root: true,
      type: MemberType.User,
      imageInitials: "DC",
    },
    {
      name: PersonaType.dalogUser,
      personaRoles: this.dalogUser,
      root: true,
      type: MemberType.User,
      imageInitials: "DU",
    },
    {
      name: PersonaType.customerUser,
      personaRoles: this.customerUser,
      root: false,
      type: MemberType.User,
      imageInitials: "CU",
    },
    {
      name: PersonaType.customerMobileUser,
      personaRoles: this.customerMobileUser,
      root: false,
      type: MemberType.User,
      imageInitials: "CMU",
    },
    {
      name: PersonaType.imageUploaderCustomer,
      personaRoles: this.imageUploaderCustomer,
      root: false,
      type: MemberType.ServiceAccount,
      imageInitials: "IUC",
    },
  ];

  public static GetUserPersona = (
    entity: EntityItemWithAccountRolesToMapInTable
  ): personaProps | null => {
    let result: personaProps | null = null;
    Personas.allPersonas.forEach((persona) => {
      // Fills up the user roles array.
      let userRolesArray: String[] = [];
      for (let key of Object.keys(roleMappings)) {
        if (entity[roleMappings[key]] === true) {
          userRolesArray.push(key);
        }
      }

      const roles = JSON.stringify(
        persona.personaRoles.filter((role) => role.toString()).sort()
      );

      const userRoles = JSON.stringify(userRolesArray.sort());
      if (roles === userRoles) {
        result = persona;
      }
    });

    return result;
  };
}
